import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import RolesGuard from '../../../components/RolesGuard';
import { NAVIGATION_URLS, ROLES } from '../../../constants';
import { FONTS } from '../../../theme';

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  labelInfo?: string;
  labelText: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
    },
    content: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      flexDirection: 'row-reverse',
      padding: 20,
      '& $labelText': {
        fontFamily: FONTS.Graphik.GraphikMedium,
        letterSpacing: '1.2px',
        lineHeight: 1.1,
        fontSize: '0.875rem',
      },
    },
    group: {
      width: '95%',
      marginLeft: '5%',
      '& a': {
        textDecoration: 'none',
      },
      '& $root': {
        marginTop: 5,
        '& $content': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.primary.main,
          '& $labelText': {
            fontFamily: FONTS.Graphik.GraphikRegular,
            letterSpacing: '1.2px',
            lineHeight: 1.1,
            fontSize: '0.75rem',
          },
        },
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  })
);

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { labelText, labelInfo, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
      '& a': {
        textDecoration: 'none',
      },
    },
  })
);

export default function GmailTreeView() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={[]}
      defaultCollapseIcon={<RemoveIcon />}
      defaultExpandIcon={<AddIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      <RolesGuard
        allowedRoles={[
          ROLES.revenuesAnalytics,
          ROLES.membersAnalytics,
          ROLES.membersUsageAnalytics,
        ]}
      >
        <StyledTreeItem nodeId="44" labelText={t('dashboard.title')}>
          <RolesGuard
            allowedRoles={[
              ROLES.revenuesAnalytics,
              ROLES.membersAnalytics,
              ROLES.membersUsageAnalytics,
            ]}
          >
            <RouterLink to={NAVIGATION_URLS.dashboard.analytics}>
              <StyledTreeItem
                nodeId="45"
                labelText={t('dashboard.analytics.title')}
              />
            </RouterLink>
          </RolesGuard>
        </StyledTreeItem>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.membersReport]}>
        <RouterLink to={NAVIGATION_URLS.members.all}>
          <StyledTreeItem nodeId="1" labelText={t('members.members')} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard
        allowedRoles={[
          ROLES.brandsReport,
          ROLES.topPerformingBrands,
          ROLES.newBrandsReport,
          ROLES.brandsChangelogReport,
        ]}
      >
        <StyledTreeItem nodeId="2" labelText={t('brands.brands')}>
          <RolesGuard allowedRoles={[ROLES.brandsReport]}>
            <StyledTreeItem nodeId="3" labelText={t('brands.brandsTab')}>
              <RouterLink to={NAVIGATION_URLS.brands.all}>
                <StyledTreeItem nodeId="4" labelText={t('brands.brands')} />
              </RouterLink>
              <RouterLink to={`${NAVIGATION_URLS.brands.all}?active=true`}>
                <StyledTreeItem
                  nodeId="5"
                  labelText={t('brands.brandsActive')}
                />
              </RouterLink>
              <RouterLink to={`${NAVIGATION_URLS.brands.all}?active=false`}>
                <StyledTreeItem
                  nodeId="6"
                  labelText={t('brands.brandsInactive')}
                />
              </RouterLink>
            </StyledTreeItem>
          </RolesGuard>
          <RolesGuard allowedRoles={[ROLES.topPerformingBrands]}>
            <RouterLink to={NAVIGATION_URLS.brands.topPerforming}>
              <StyledTreeItem nodeId="7" labelText={t('brands.topBrandsTab')} />
            </RouterLink>
          </RolesGuard>
          <RolesGuard allowedRoles={[ROLES.newBrandsReport]}>
            <RouterLink to={NAVIGATION_URLS.brands.newBrands}>
              <StyledTreeItem nodeId="8" labelText="new brands" />
            </RouterLink>
          </RolesGuard>
          <RolesGuard allowedRoles={[ROLES.brandsChangelogReport]}>
            <RouterLink to={NAVIGATION_URLS.brands.brandsChangelog}>
              <StyledTreeItem nodeId="9" labelText="brands changelog" />
            </RouterLink>
          </RolesGuard>
        </StyledTreeItem>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.totalRevenuesReport]}>
        <StyledTreeItem nodeId="10" labelText={t('revenues.revenues')}>
          <RouterLink to={NAVIGATION_URLS.revenues}>
            <StyledTreeItem nodeId="11" labelText={t('revenues.totalTab')} />
          </RouterLink>
        </StyledTreeItem>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.transactionsReport]}>
        <StyledTreeItem nodeId="12" labelText={t('transactions.transactions')}>
          <RouterLink to={NAVIGATION_URLS.transactions.all}>
            <StyledTreeItem nodeId="42" labelText={t('transactions.allTab')} />
          </RouterLink>
          <RouterLink to={NAVIGATION_URLS.transactions.rakuten}>
            <StyledTreeItem
              nodeId="13"
              labelText={t('transactions.rakutenTab')}
            />
          </RouterLink>
          <RouterLink to={NAVIGATION_URLS.transactions.impact}>
            <StyledTreeItem
              nodeId="14"
              labelText={t('transactions.impactTab')}
            />
          </RouterLink>
          <RouterLink to={NAVIGATION_URLS.transactions.cj}>
            <StyledTreeItem nodeId="15" labelText={t('transactions.cjTab')} />
          </RouterLink>
          <RouterLink to={NAVIGATION_URLS.transactions.partnerize}>
            <StyledTreeItem
              nodeId="16"
              labelText={t('transactions.partnerizeTab')}
            />
          </RouterLink>
          <RouterLink to={NAVIGATION_URLS.transactions.shareASale}>
            <StyledTreeItem
              nodeId="17"
              labelText={t('transactions.shareASaleTab')}
            />
          </RouterLink>
          <RouterLink to={NAVIGATION_URLS.transactions.pepperjam}>
            <StyledTreeItem
              nodeId="18"
              labelText={t('transactions.pepperjamTab')}
            />
          </RouterLink>
          <RouterLink to={NAVIGATION_URLS.transactions.awin}>
            <StyledTreeItem nodeId="19" labelText={t('transactions.awinTab')} />
          </RouterLink>
          <RouterLink to={NAVIGATION_URLS.transactions.ebay}>
            <StyledTreeItem nodeId="20" labelText={t('transactions.ebayTab')} />
          </RouterLink>
        </StyledTreeItem>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.shortLinksReport]}>
        <RouterLink to={NAVIGATION_URLS.shortLinks}>
          <StyledTreeItem nodeId="43" labelText={'share links'} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard
        allowedRoles={[ROLES.cashBackPaidReport, ROLES.cashBackToPayReport]}
      >
        <StyledTreeItem nodeId="21" labelText={t('payments.cashback')}>
          <RolesGuard allowedRoles={[ROLES.cashBackPaidReport]}>
            <RouterLink to={NAVIGATION_URLS.payments.all}>
              <StyledTreeItem
                nodeId="22"
                labelText={t('payments.cashbackPaid')}
              />
            </RouterLink>
          </RolesGuard>
          <RolesGuard allowedRoles={[ROLES.cashBackToPayReport]}>
            <RouterLink to={NAVIGATION_URLS.payments.toPay}>
              <StyledTreeItem
                nodeId="23"
                labelText={t('readyToPay.cashback')}
              />
            </RouterLink>
          </RolesGuard>
        </StyledTreeItem>
      </RolesGuard>
      <RolesGuard
        allowedRoles={[
          ROLES.referralBonusPaidReport,
          ROLES.referralBonusToPayReport,
          ROLES.referralBonusPending,
        ]}
      >
        <StyledTreeItem nodeId="24" labelText={t('payments.referral')}>
          <RolesGuard allowedRoles={[ROLES.referralBonusPaidReport]}>
            <RouterLink to={NAVIGATION_URLS.payments.referrals}>
              <StyledTreeItem
                nodeId="25"
                labelText={t('payments.referralPaid')}
              />
            </RouterLink>
          </RolesGuard>
          <RolesGuard allowedRoles={[ROLES.referralBonusToPayReport]}>
            <RouterLink to={NAVIGATION_URLS.payments.referralsToPay}>
              <StyledTreeItem
                nodeId="26"
                labelText={t('readyToPay.referral')}
              />
            </RouterLink>
          </RolesGuard>
          <RolesGuard allowedRoles={[ROLES.referralBonusPending]}>
            <RouterLink to={NAVIGATION_URLS.payments.pendingReferralsPayments}>
              <StyledTreeItem
                nodeId="41"
                labelText={t('readyToPay.pendingBonus')}
              />
            </RouterLink>
          </RolesGuard>
        </StyledTreeItem>
      </RolesGuard>
      <RolesGuard
        allowedRoles={[
          ROLES.sharedCommissionPaidReport,
          ROLES.sharedCommissionToPayReport,
        ]}
      >
        <StyledTreeItem nodeId="27" labelText={t('payments.proShared')}>
          <RolesGuard allowedRoles={[ROLES.sharedCommissionPaidReport]}>
            <RouterLink to={NAVIGATION_URLS.payments.proShareCommissions.all}>
              <StyledTreeItem
                nodeId="28"
                labelText={t('payments.proSharedPaid')}
              />
            </RouterLink>
          </RolesGuard>
          <RolesGuard allowedRoles={[ROLES.sharedCommissionToPayReport]}>
            <RouterLink to={NAVIGATION_URLS.payments.proShareCommissions.toPay}>
              <StyledTreeItem
                nodeId="29"
                labelText={t('readyToPay.proShared')}
              />
            </RouterLink>
          </RolesGuard>
        </StyledTreeItem>
      </RolesGuard>
      <RolesGuard allowedRoles={[]}>
        <RouterLink to={NAVIGATION_URLS.admins.all}>
          <StyledTreeItem nodeId="30" labelText={t('admins.admins')} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard allowedRoles={[]}>
        <RouterLink to={NAVIGATION_URLS.exportLogs.all}>
          <StyledTreeItem nodeId="43" labelText={t('exportLogs.exportLogs')} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard allowedRoles={[]}>
        <StyledTreeItem nodeId="31" labelText={t('settings.settings')}>
          <RolesGuard allowedRoles={[]}>
            <RouterLink to={NAVIGATION_URLS.settings.all}>
              <StyledTreeItem
                nodeId="42"
                labelText={t('settings.generalSettings')}
              />
            </RouterLink>
          </RolesGuard>
          <RolesGuard allowedRoles={[]}>
            <RouterLink to={NAVIGATION_URLS.settings.joinPages}>
              <StyledTreeItem
                nodeId="41"
                labelText={t('settings.joinPageSetting')}
              />
            </RouterLink>
          </RolesGuard>
        </StyledTreeItem>
        {/* <RouterLink to={NAVIGATION_URLS.settings.all}>
          <StyledTreeItem nodeId="31" labelText={t('settings.settings')} />
        </RouterLink> */}
      </RolesGuard>
      <RouterLink to={NAVIGATION_URLS.createShareLink}>
        <StyledTreeItem
          nodeId="32"
          labelText={t('createLink.createShareLink')}
        />
      </RouterLink>
      <RouterLink to={NAVIGATION_URLS.subscription}>
        <StyledTreeItem
          nodeId="47"
          labelText={t('subscription.subscription')}
        />
      </RouterLink>

      <RolesGuard allowedRoles={[]}>
        <StyledTreeItem nodeId="48" labelText={t('topBanner.bannerEditor')}>
          <RolesGuard allowedRoles={[ROLES.manageTopBanners]}>
            <RouterLink to={NAVIGATION_URLS.topBanner}>
              <StyledTreeItem
                nodeId="33"
                labelText={t('topBanner.pageTitle')}
              />
            </RouterLink>
          </RolesGuard>
          <RolesGuard allowedRoles={[ROLES.manageTopBanners]}>
            <RouterLink to={NAVIGATION_URLS.topBannerSafari}>
              <StyledTreeItem
                nodeId="49"
                labelText={t('topBannerIOS.pageTitleSafari')}
              />
            </RouterLink>
          </RolesGuard>
          <RolesGuard allowedRoles={[ROLES.manageTopBanners]}>
            <RouterLink to={NAVIGATION_URLS.topBannerChrome}>
              <StyledTreeItem
                nodeId="50"
                labelText={t('topBannerIOS.pageTitleChrome')}
              />
            </RouterLink>
          </RolesGuard>
        </StyledTreeItem>
      </RolesGuard>

      <RolesGuard allowedRoles={[ROLES.manageCmsContent]}>
        <RouterLink to={NAVIGATION_URLS.cms}>
          <StyledTreeItem nodeId="34" labelText={'cms editor'} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.manageProductCategories]}>
        <RouterLink to={NAVIGATION_URLS.categories}>
          <StyledTreeItem nodeId="35" labelText={t('categories.pageTitle')} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.manageProductCatalogues]}>
        <RouterLink to={NAVIGATION_URLS.productCatalogues}>
          <StyledTreeItem nodeId="36" labelText={t('catalogues.pageTitle')} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.manageTrendingProducts]}>
        <RouterLink to={NAVIGATION_URLS.trending}>
          <StyledTreeItem nodeId="37" labelText={t('trending.pageTitle')} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.manageTopProducts]}>
        <RouterLink to={NAVIGATION_URLS.topProducts}>
          <StyledTreeItem nodeId="46" labelText={t('topProducts.pageTitle')} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.manageTopProductsPerCategory]}>
        <RouterLink to={NAVIGATION_URLS.topCategoryProducts}>
          <StyledTreeItem
            nodeId="38"
            labelText={t('topCategoryProducts.pageTitle')}
          />
        </RouterLink>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.productParserLog]}>
        <RouterLink to={NAVIGATION_URLS.productParserLogs}>
          <StyledTreeItem nodeId="39" labelText={'product parser logs'} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.membersReport]}>
        <RouterLink to={NAVIGATION_URLS.paymentHeldLogs}>
          <StyledTreeItem nodeId="40" labelText={'payment held logs'} />
        </RouterLink>
      </RolesGuard>
      <RolesGuard allowedRoles={[ROLES.manageEditorPickPages]}>
        <RouterLink to={NAVIGATION_URLS.editPages}>
          <StyledTreeItem nodeId="41" labelText={'edit pages'} />
        </RouterLink>
      </RolesGuard>
    </TreeView>
  );
}
